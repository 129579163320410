  /*
   * Copyright(c) 2021 eCert, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 登录
    login: data => {
      return fetch({
        url: 'login',
        method: 'post',
        data
      })
    },
    // 获取手机验证码
    sendCaptcha: data => {
      return fetch({
        url: 'send-captcha',
        method: 'post',
        data
      })
    },
    // 找回密码
    resetPwd: data => {
      return fetch({
        url: 'users/reset-password-new',
        method: 'put',
        data
      })
    }
  }