<template>
  <div class="register">
    <div class="container">
      <div class="content">
        <Row class="height">
          <Col class-name="height logoHeight" :lg="12" :xs="24">
            <img class="bigLogo" src="/img/logo.png" alt="">
          </Col>
          <Col class-name="height formHeight" :lg="12" :xs="24">
            <div class="formWrap">
              <div class="inlineBlockWrap">
                <div class="inlineBlock myBar">
                  <p class="registerTitle">欢迎使用设计瑰谷</p>
                  <div class="center titleItem">重置密码</div>
                  <Form ref="form" :model="info" :rules="rules" hide-required-mark class="formStyle">
                    <FormItem prop="mobilePhone" label="手机">
                      <Input type="tel" v-model="info.mobilePhone"></Input>
                    </FormItem>
                    <FormItem prop="captcha" label="短信验证码">
                      <Input type="text" class="msgCodeInput" v-model="info.captcha"></Input>
                      <Button shape="circle" :disabled="codeBtnText!='获取验证码'" class="defaultBtn msgCodeBtn" @click="sendMsgCode">{{codeBtnText}}</Button>
                    </FormItem>
                    <FormItem prop="password" label="登录密码">
                      <Input type="password" v-model="info.password"></Input>
                    </FormItem>
                    <FormItem prop="confirmPassword" label="确认登录密码">
                      <Input type="password" v-model="info.confirmPassword" @on-enter="submit"></Input>
                    </FormItem>
                  </Form>
                  <Button shape="circle" long :loading="submitting" class="defaultBtn" @click="submit">重置密码</Button>
                  <div class="center">
                    <router-link to="/login" class="blackHref">去登录</router-link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import centerService from "@/api/center";
export default {
  name: "Index",
  data() {
    return {
      info: {},
      rules: {
        mobilePhone: [
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: "请正确输入手机号码",
            trigger: "blur,change"
          }
        ],
        captcha: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur,change"
          }
        ],
        password: [
          {
            required: true,
            message: "密码需8-16位数字英文符号两种以上组合",
            // pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/,
            pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![?~!=,#&@$%.+*()|{}"<>'\-:;^_`]+$)[?~!=,#&@$%.+*()|{}"<>'\-:;^_`0-9A-Za-z]{8,16}$/,
            trigger: "blur,change"
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: "需与登录密码相同",
            validator: (rule, value) => {
              if (!value || value != this.info.password) {
                return false;
              }
              return true;
            },
            trigger: "blur,change"
          }
        ]
      },
      submitting: false,
      codeBtnText: "获取验证码"
    };
  },
  mounted() {},
  methods: {
    // 提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true;
          let condition = {
            mobilePhone: this.info.mobilePhone,
            code: this.info.captcha,
            newPassword: this.info.password
          };
          centerService
            .resetPwd(condition)
            .then(res => {
              if (res.errorNo == 0) {
                this.$Message.success("密码重置成功！");
                this.$router.push("/login");
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.submitting = false;
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("信息填写有误，请重新检查");
        }
      });
    },
    // 获取验证码
    sendMsgCode() {
      this.$refs.form.validateField("mobilePhone", valid => {
        if (valid) {
          this.$Message.error("请输入正确的手机号");
        } else {
          let condition = {
            smsType: "RESET_PASSWORD",
            mobilePhone: this.info.mobilePhone
          };
          let timer = null;
          centerService.sendCaptcha(condition).then(res => {
            if (res.errorNo == 0) {
              let time = 60,
                _this = this;
              timer = setInterval(function() {
                time--;
                _this.codeBtnText = `重新获取(${time})`;
                if (time == 0) {
                  clearInterval(timer);
                  _this.codeBtnText = "获取验证码";
                }
              }, 1000);
            } else {
              this.$Message.error(res.errorMessage);
            }
          });
          this.$once("hook:beforeDestroy", () => {
            clearInterval(timer);
            this.codeBtnText = "获取验证码";
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.register {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url(/img/registerBg.jpg) no-repeat center;
  background-size: cover;
  padding: 50px 0;

  .container {
    height: 100%;
  }

  .content {
    position: relative;
    border-radius: 10px;
    height: 100%;
    background: url(/img/registerBg2.jpg) no-repeat left #ffffff;
    background-size: 75% 100%;
    padding: 15px 50px;

    .height {
      height: 100%;
    }
    .bigLogo {
      position: absolute;
      top: 50%;
      left: 20%;
      max-width: 80%;
    }
    .registerTitle {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .formWrap {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #ffffff;
      box-shadow: 6px 6px 15px 3px rgba($color: #506b78, $alpha: 0.5);
      border-top: 2px solid #00b7ee;
      height: 100%;
      overflow: hidden;

      .inlineBlockWrap {
        height: 100%;
        width: 200%;
        white-space: nowrap;
        font-size: 0;
      }
      .inlineBlock {
        font-size: 14px;
        width: 50%;
        max-height: 100%;
        display: inline-block;
        white-space: normal;
        padding: 15px 30px 30px;
        vertical-align: top;
        overflow: auto;
      }
      .msgCodeInput {
        padding-right: 110px;
      }
      .defaultBtn {
        margin: 20px 0;
      }
      .msgCodeBtn {
        text-align: center;
        position: absolute;
        right: 0;
        // top: 24px;
        width: 110px;
        padding: 0;
        margin: 0;
      }
      .blackHref {
        color: #221715;
      }
      .floatRight {
        float: right;
      }
    }
  }
}
.titleItem {
  padding-bottom: 5px;
}
@media (max-width: 768px) {
  .register .content {
    padding: 15px;

    .logoHeight {
      height: 20%;

      .bigLogo {
        max-width: 50%;
        transform: translateY(-50%);
      }
    }
    .formHeight {
      height: 80%;
    }
  }
}
</style>